export const NOT_YET_LINKED_ERROR = "authTokenNotLinked";
export const UNKNOWN_ERROR = "unknownError";
export const NO_REGION_ERROR = "noRegion";
export const NO_TOKEN_ERROR = "noToken";
export const REGISTRATION_FAILED_ERROR = "registrationFailed";
export const PERSISTENCE_OUT_OF_SYNC_ERROR = "persistenceOutOfSyncError";
export const NETWORK_CONNECTION_ERROR = "networkConnectionError";
export const INVALID_URL_CODE_ERROR = "invalidUrlCodeError";
export const SSO_LOGIN_FAILED_ERROR = "ssoLoginFailedError";
export const SSO_DESTINATION_NOT_FOUND_ERROR = "ssoDestinationNotFoundError";
export const INVALID_ACKNOWLEDGEMENT_ERROR = "invalidAcknowledgementError";
export const INVALID_VERIFICATION_CODE_ERROR = "invalidVerificationCodeError";

export const createPersistenceSyncError = (
  valueKey: string,
  cookieValue: string,
  localStorageValue: string
): Error => {
  const error = new Error(
    `Persistence out of sync for key: ${valueKey}. Cookie value: ${cookieValue}. Local Storage value: ${localStorageValue}`
  );
  error.name = PERSISTENCE_OUT_OF_SYNC_ERROR;
  return error;
};

export const createNotYetLinkedError = () => {
  const error = new Error("Screen not yet linked");
  error.name = NOT_YET_LINKED_ERROR;
  return error;
};

export const createInvalidVerificationCodeError = () => {
  const error = new Error("Invalid code. Please try again.");
  error.name = INVALID_VERIFICATION_CODE_ERROR;
  return error;
};

export const createInvalidAcknowledgementError = () => {
  const error = new Error(
    "Order cannot be acknowledge. It may have been updated by another user"
  );
  error.name = INVALID_ACKNOWLEDGEMENT_ERROR;
  return error;
};

export const captureNetworkErrors = (error: any) => {
  // Cancelled refers to the timeouts set with an AbortController
  // Other network errors such as no internet connection will have Network Error message
  if (error?.message === "Network Error" || error.message === "canceled") {
    throw createNetworkError();
  }
};

export const captureInvalidVerificationCodeError = (error: any) => {
  const errorMessage = error.response?.data?.errorMessages?.errorDescription;
  if (errorMessage) {
    const invalidCodeRegex = /Invalid code. Please try again./;
    if ((errorMessage as string).match(invalidCodeRegex)) {
      throw createInvalidVerificationCodeError();
    }
  }
};

export const createNetworkError = () => {
  const error = new Error("Unable to connect to the network");
  error.name = NETWORK_CONNECTION_ERROR;
  return error;
};

export const createInvalidUrlCodeError = () => {
  const error = new Error("Invalid URL code");
  error.name = INVALID_URL_CODE_ERROR;
  return error;
};

export const createUnknownError = (error?: unknown) => {
  if (error && Object.prototype.hasOwnProperty.call(error, "message")) {
    const errorObject = error as Error;
    console.log("An unidentified error occurred", error);
    errorObject.name = UNKNOWN_ERROR;
    return errorObject;
  }
  const newError = new Error("An unidentified error occurred");
  newError.name = UNKNOWN_ERROR;
  return newError;
};

export const createNoRegionError = () => {
  const error = new Error("Region has not been set in localStorage");
  error.name = NO_REGION_ERROR;
  return error;
};

export const createNoTokenError = () => {
  const error = new Error("Token has not been set in localStorage");
  error.name = NO_TOKEN_ERROR;
  return error;
};

export const createRegistrationFailedError = () => {
  const error = new Error("Failed to register new code.");
  error.name = REGISTRATION_FAILED_ERROR;
  return error;
};

export const createSsoLoginFailedError = () => {
  const error = new Error("Failed to exchange code for idToken");
  error.name = SSO_LOGIN_FAILED_ERROR;
  return error;
};

export const createSsoDestinationNotFoundError = () => {
  const error = new Error("destinationId not found");
  error.name = SSO_DESTINATION_NOT_FOUND_ERROR;
  return error;
};
